





































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TsUpload from '@/components/TsForm/tsUpload.vue'
import { StoryLineMap, GroupMap } from '@/types/uploadStory'
import { storyLineIasList } from '@/api/uploadStory'
import { storyLineTypeListWealthFocus as _storyLineTypeList } from '../utils'
@Component({
  components: {
    TsUpload
  }
})
export default class Edit extends Vue {
  @Prop() editInfo!: StoryLineMap
  activeName = 'base'
  form = {}

  storyLineTypeList = _storyLineTypeList
  // groupList: GroupMap[] = []

  created() {
    this.setForm()
  }

  private setForm() {
    this.form = this.editInfo
    // this.storyLineIasList(this.editInfo.storyLineType)
  }

  // private async storyLineIasList(storyLineType: string) {
  //   const { data } = await storyLineIasList({ storyLineType })
  //   if (data && data.length) {
  //     this.groupList = data
  //   }
  // }
}
