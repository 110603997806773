



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  computed: {
    btnType: () => (type: string) => type || 'primary',
    btnSize: () => (type: any) => type ? 'mini' : 'medium'
  }
})
export default class TsDropdown extends Vue {
  /**
   * todo 当前组件处理按钮（下拉按钮）操作
   * 功能
   *  1. 判断当前组件是否支持下拉功能(dropdownObj.dropdown),
   *      true 显示下拉页面
   *      false 按钮正常排版
   *          如果 type 为 true 说明当前按钮位于表格，那么 size设置 mini
   *          否则 按钮正常显示
   *
   * 参数
   *      dropdownObj 存放按钮对象
   *      type    类型
   *          true 按钮显示在表格内(编辑位置，表格最后一行)
   *          false 按钮显示在 表格 title 右侧
   *      tableItem 表格当前项
   *
   * 事件
   *      handleTableBtn 点击按钮触发
   */
  @Prop() dropdownObj: any
  @Prop() type!: boolean
  @Prop() table!: any
  @Prop() tableItem!: any
  @Prop() tableIdx!: number

  /**
   * handleTableBtn 点击按钮触发
   */
  handleTableBtn(type: string) {
    let emitList = []
    if (this.type) {
      emitList = [type, undefined]
    } else {
      emitList = [type, this.tableItem, this.tableIdx, this.table]
    }
    this.$emit('on-handle-table-btn', ...emitList)
  }
}
