


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { comdify2, isPhone, messageBoxFn } from '@/utils/validate'
import TsPagination from './TsPagination.vue'
import TsTableHeadPopover from './TsHeadPopover.vue'
import TsDropdown from './TsDropdown.vue'
import { formatRoundToFixed, IsToFixed } from '@/utils/IsToFixed'
import { rmbFilterNum } from '@/utils/filter'
// import moduleHeader from '@/packages/nb-amc-web-components/moduleHeader/index.vue'

@Component({
  components: {
    TsDropdown,
    TsPagination,
    TsTableHeadPopover
    // moduleHeader
  }
})
export default class TsTable extends Vue {
  /**
   * todo 当前组件为表格入口文件
   * paginationHide是否展示分页
   * tableAllParams table全部数据
   *  tableTitleObj 表格title信息
   *    title 表格标签信息
   *    options 表格标签右侧信息
   *  tableData 表格数据包含
   *    data 表格数据
   *    currentPage 当前页数
   *    pageSize  每页显示条目个数
   *    total   总数
   * tableAlign 表格（表头、表体）位置 left（默认） center、 right
   * tags 过滤筛选表格头部数组信息
   * selectArr 复选框选中数组集合
   * radioValue 单选框的值
   *
   * todo tableHead
   *   filterBody
   *      [{type: '类型', name: '要显示的内容'}]
   *      功能 表体单元格数据过滤
   *
   */

  @Prop({
    default: () => {
      return { hide: false }
    }
  })
  private tableTitleObj!: any
  @Prop({ default: false }) private paginationHide!: boolean
  @Prop() private tableData!: any
  @Prop() private tableAllParams!: any
  @Prop() private tableHead!: any
  @Prop() private tableSelectOpt!: any[]
  @Prop({ default: 'center' }) private tableAlign!: string
  @Prop() private noPaginationHeight?: number
  @Prop({ default: false }) private showSummay?: boolean
  @Prop() private spanMethod?: Function
  @Prop() private cellStyle?: Function
  @Prop() private headerCellStyle?: Function
  @Prop() private getSummaries?: Function
  @Prop() private rowClassName?: Function
  @Prop({ default: true }) private showHeader!: boolean

  height = 100
  tags = [
    // { name: "活动类型：标签一", lableType: "info", type: 'name', params:{...} },
  ]

  selectArr: any = []
  firstS = false
  radioValue = ''
  defaultFlag = true
  formatRoundToFixed: Function = formatRoundToFixed
  comdify2: Function = comdify2
  IsToFixed: Function = IsToFixed
  rmbFilterNum: Function = rmbFilterNum
  @Watch('tags')
  watchTag() {
    this.$nextTick(() => {
      this.getTableHeight()
    })
  }

  @Watch('selectArr')
  watchSelect() {
    this.$nextTick(() => {
      this.getTableHeight()
    })
  }

  mounted() {
    this.$nextTick(() => {
      this.getTableHeight()
      window.addEventListener('resize', this.getTableHeight)
    })
  }
  dragCount = 0

  headerDrag(newWidth: number, oldWidth: number, column: any, event: any) {
    const tableEl = this.tableRef()
    const outEl = this.$refs.outTable as HTMLElement
    const outWidth = outEl.offsetWidth
    const { columns } = tableEl
    const columnNum = columns.length
    const unitWidth = Math.floor(outWidth / columnNum)
    const maxWidth = unitWidth * 2.5
    const minWidth = unitWidth / 3
    if (newWidth > maxWidth || newWidth < minWidth) {
      column.width = oldWidth
    }
    this.tableRef().doLayout()
  }

  switchChange(scope: any) {
    this.$emit('switchChange', scope)
  }

  beforeUpdate() {
    this.handleSelectDefault()
  }

  filterCell(tableHeadItem: any, scpoeRow: any) {
    const _filterBody = tableHeadItem.filterBody
    let _value = ''
    if (_filterBody) {
      _value =
        (_filterBody.find(
          (w: any) => w.type === scpoeRow[tableHeadItem.propName]
        ) &&
          _filterBody.find(
            (w: any) => w.type === scpoeRow[tableHeadItem.propName]
          ).name) ||
        '-'
    } else {
      _value = scpoeRow[tableHeadItem.propName] || '-'
    }
    // showType 表格数据的展示形式 如: 百分比
    if (tableHeadItem.showType === 'percent') {
      return _value !== '-'
        ? `${formatRoundToFixed(Number(_value), 100, 2)}%`
        : _value
    }

    // showType 表格数据的展示形式 如: 百分比
    if (tableHeadItem.showType === 'headHoverAndPercent') {
      return _value !== '-'
        ? `${formatRoundToFixed(Number(_value), 100, 2)}%`
        : _value
    }

    if (tableHeadItem.showType === 'other') {
      return _value
    }

    if (isPhone(_value)) {
      return _value
    }
    // 千分位形式展示
    if (Number(_value)) {
      return comdify2(_value)
    } else {
      return _value
    }
  }
  handleSelectDefault() {
    const { tableHead } = this
    if (
      tableHead &&
      tableHead.length > 0 &&
      (tableHead[0].type === 'radio' || tableHead[0].type === 'select') &&
      tableHead[0].defaultValue
    ) {
      const { tableData, defaultFlag } = this
      if (defaultFlag) {
        if (tableData && tableData.data && tableData.data.length > 0) {
          const { type } = tableHead[0]
          const _defaultValue = tableHead[0].defaultValue
          const _data = tableData.data
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _that = this
          const obj: any = {
            setArr(arr: any[], opt: any) {
              opt && arr.push(opt)
            },
            handleType(flag = false) {
              const arr: any = []
              if (_defaultValue instanceof Array) {
                for (let i = 0; i < _defaultValue.length; i++) {
                  const _find = _data.find(
                    (item: any) => item.id === _defaultValue[i]
                  )
                  if (flag) {
                    _that.tableRef().toggleRowSelection(_find)
                  }
                  this.setArr(arr, _find)
                }
              } else {
                const _find = _data.find(
                  (item: any) => item.id === _defaultValue
                )
                if (flag) {
                  _that.tableRef().toggleRowSelection(_find)
                }
                this.setArr(arr, _find)
              }
              return arr
            },
            radio() {
              _that.radioValue = _defaultValue
              return this.handleType()
            },
            select() {
              return this.handleType(true)
            }
          }
          this.selectArr = obj[type]()
        }
      }
    }
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.getTableHeight)
  }

  getTableHeight() {
    // const offsetTop = (document.querySelector('.el-table') as HTMLDialogElement).offsetTop
    const innerHeight = window.innerHeight
    const { paginationHide } = this
    if (paginationHide) return
    const pagination = (document as any).querySelector('.el-pagination')
    let _H = innerHeight - 66 - pagination.offsetHeight
    if (this.firstS) {
      _H -= 70 + 32
    } else {
      this.firstS = true
      _H -= 70 + 32
    }
    this.height = _H
  }
  onHandleFun(item: any, scope: any) {
    this.$emit('handleFun', item, scope)
  }

  // 表格选择方法
  // handleSelectAll 全选
  handleSelectChange(sele: any) {
    this.defaultFlag = false
    this.selectArr = sele
  }

  // 表格选择方法
  // handleSelectAll 全选
  handleRadioValue(sele: any) {
    this.defaultFlag = false
    this.selectArr = [].concat(sele)
  }

  // 清除选中
  clearSelect() {
    this.selectArr = []
    this.radioValue = ''
    this.defaultFlag = false
    this.tableRef().clearSelection()
  }

  tableRef() {
    return (this as any).$refs.tableRef
  }

  /**
   * handleSelectInfo 处理选中 btn
   * selectArr 选中内容
   * item 点击当当前项
   * idx  item索引
   */
  handleSelectInfo(item: any, idx: number) {
    messageBoxFn(this, (flag: boolean) => {
      if (flag) {
        this.$emit('on-handle-select-info', this.selectArr, item, idx)
      }
    })
  }

  /**
   * TODO handleCloseTag 处理关闭tag
   *  tag 当前项，
   *  idx 当前索引
   */
  handleCloseTag(tag: any, idx: number) {
    this.tags.splice(idx, 1)
    const { labelName, options } = tag.params.head
    const { type } = options
    if (type === 'search' || type === 'select') {
      const _findIdx = this.tableHead.findIndex(
        (w: any) => w.labelName === labelName
      )
      if (_findIdx !== -1) {
        const ele = (this as any).$refs.refHeadPopover[_findIdx]
        ele.setValue('')
        ele.setSelectValue('')
      }
    }
    this.handleFilter()
  }

  /**
   * handleTableHeadSelect 点击表格头部下拉选择框
   * itemHead 当前列头部信息
   * opt 当前项
   */
  handleTableHeadSelect(itemHead: any, opt: any, type: string) {
    const _obj: any = {
      type: itemHead.propName,
      params: {
        head: itemHead,
        opt
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _that = this
    const obj: any = {
      sort: () => {
        const arr: any = []
        _that.tags &&
          _that.tags.forEach((item: any) => {
            if (item.params.head.options.type !== 'sort') {
              arr.push(item)
            }
          })
        _that.tags = arr
        _obj.name = `${itemHead.labelName}:${opt.name}`
      },
      select: () => {
        _obj.name = `${itemHead.labelName}:${opt.label}`
      }
    }
    obj[type]()
    this.isAddTags(_obj)
    this.$emit('on-handle-table-head-select', this.tags)
    this.handleFilter()
  }

  /**
   * handleTableHeadSearch 点击表格头部下拉搜索确认功能
   * itemHead 当前列头部信息
   * value 输入框内容
   */
  handleTableHeadSearch(itemHead: any, value: string) {
    this.isAddTags({
      name: `${itemHead.labelName}:${value}`,
      type: itemHead.propName,
      params: {
        head: itemHead,
        opt: {
          value
        }
      }
    })
    this.handleFilter()
  }

  /**
   *  handleClearFilter 清除全部
   */
  handleClearFilter() {
    this.tags = []
    const refHeadPopover = (this as any).$refs.refHeadPopover
    refHeadPopover &&
      refHeadPopover.forEach((ele: any) => {
        ele.setValue('')
        ele.setSelectValue('')
      })
    this.handleFilter()
  }

  /**
   * handleFilter 处理过滤信息
   */
  handleFilter() {
    this.$emit('on-handle-filter', this.tags)
  }

  // 分页方法
  handleSizeChange(val: number) {
    this.$emit('size-change', val)
  }

  handleCurrentChange(val: number) {
    this.$emit('current-change', val)
  }

  // 添加数据到tags中
  isAddTags(obj: any) {
    obj.lableType = ''
    const _findIndex = this.tags.findIndex(
      (item: any) => item.type === obj.type
    )
    if (_findIndex !== -1) {
      (this.tags as any).splice(_findIndex, 1, obj)
    } else {
      (this.tags as any).push(obj)
    }
  }

  /**
   *   点击下拉或者按钮按钮触发
   *    type 按钮类型
   *    tableItem 如果按钮存在表格中 tableItem就是当前项
   */
  handleTableBtn() {
    this.$emit('on-handle-table-btn', ...arguments)
  }
}
