























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TsDetailTab from '@/components/TsDetailTab/index.vue'
import {
  StoryLineMap,
  BtnList
} from '@/types/uploadStory'
import { storyLineOnline } from '@/api/uploadStory'
import Add from './add.vue'
import Edit from './edit.vue'
@Component({
  name: '',
  components: {
    TsDetailTab,
    Add,
    Edit
  }
})
export default class extends Vue {
  @Prop({ default: false }) drawerVisible!: boolean
  @Prop() editType!: string
  @Prop() editInfo!: StoryLineMap

  peditType = ''
  btnList: BtnList[] = []
  headerInfo = {}

  @Watch('editType')
  handleEditType(newValue: string) {
    this.btnList = []
    this.headerInfo = {}
    switch (newValue) {
      case 'add':
        this.handleAddSelect()
        break

      case 'edit':
        this.handleEditSelect()
        break
    }
    this.peditType = newValue
  }

  handleAddSelect() {
    this.btnList = []
    const headerInfo = {
      title: '新增',
      blockList: [
        {
          key: '最后编辑人',
          value: '-'
        },
        {
          key: '最后修改日',
          value: '-'
        }
      ]
    }
    this.headerInfo = headerInfo
  }

  handleEdit() {
    const { updateBy, lastUpdateTime } = this.editInfo
    const headerInfo = {
      title: '编辑',
      blockList: [
        {
          key: '最后编辑人',
          value: updateBy
        },
        {
          key: '最后修改日',
          value: lastUpdateTime
        }
      ]
    }
    this.peditType = 'add'
    this.headerInfo = headerInfo
  }

  handleEditSelect() {
    const { online, updateBy, lastUpdateTime } = this.editInfo
    const btnList = [
      {
        type: 'switch',
        value: !online,
        btnFuc: this.handleBtn
      }
    ]

    const headerInfo = {
      title: '编辑',
      status: online ? '已激活' : '未激活',
      blockList: [
        {
          key: '最后编辑人',
          value: updateBy
        },
        {
          key: '最后修改日',
          value: lastUpdateTime
        }
      ]
    }
    this.btnList = btnList
    this.headerInfo = headerInfo
  }

  private handleCloseDrawer(status: boolean) {
    this.emptyPData()
    this.$emit('on-close-drawer', status)
  }

  emptyPData() {
    this.peditType = ''
    this.btnList = []
    this.headerInfo = {}
  }

  async handleBtn(online: boolean) {
    await storyLineOnline({
      storyLineId: this.editInfo.id || '',
      online: !online
    })
    this.$message({
      type: 'success',
      message: online ? '下架成功' : '上架成功'
    })
  }
}
