import request from '@/utils/request'
import { Pagination } from '@/types/common'
import {
  StoryLineMap,
  StoryLineDetail,
  StoryLineOnline,
  StoryLineIasType
} from '@/types/uploadStory'

// 列表
export const storyLineList = (params: Pagination) =>
  request({
    url: '/blackcat/story/line/page',
    method: 'get',
    params
  })

// 新增保存
export const storyLineSave = (data: StoryLineMap) =>
  request({
    url: '/blackcat/story/line/save',
    method: 'post',
    data
  })

// 详情
export const storyLineDetail = (params: StoryLineDetail) =>
  request({
    url: '/blackcat/story/line/detail',
    method: 'get',
    params
  })

// 上/下架
export const storyLineOnline = (params: StoryLineOnline) =>
  request({
    url: '/blackcat/story/line/online',
    method: 'post',
    params
  })
// 运算模组选择
export const storyLineIasList = (params: StoryLineIasType) =>
  request({
    url: '/blackcat/ias/type',
    method: 'get',
    params
  })
