










































































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  computed: {
    optIconType: () => (type: string) => {
      let icon = ''
      if (type === 'sort') {
        icon = 'el-icon-d-caret color-blue cursor-p'
      } else if (type === 'html') {
        icon = 'el-icon-question help-icon icon-right'
      } else {
        icon = 'el-icon-caret-bottom color-blue cursor-p'
      }
      return icon
    }
  }
})
export default class TsTableHeadPopover extends Vue {
  /**
   * TODO 当前组件处理表格头部 气泡相关操作
   *  功能
   *      1. 只用 item.options 存在才会进入
   *      2. 如果 item.options.type 等于搜索(search)状态气泡显示搜索功能
   *      2. 如果 item.options.type 属于 下拉(select)、排序(sort) 这俩类型显示实现下拉功能
   *
   * 参数
   *      item   当前列表头信息
   *      value  item.options.type === search 时输入框内容
   *
   * 方法
   *      on-handle-table-head-search type===search 时点击确认触发
   *      on-handle-table-head-select type！==search 时点击下拉项触发
   */
  @Prop() item!: any
  value = ''
  selectValue = ''
  handleConfirm(value: string, item: any) {
    value && this.$emit('on-handle-table-head-search', item, value)
    this.closePopover(false)
  }

  handleTableHeadSelect(type: string, opt: any) {
    const { item } = this
    if (type === 'select') {
      this.$emit(
        'on-handle-table-head-select',
        item,
        item.options.optionArr.find(
          (item: any) => item.id === this.selectValue
        ),
        type
      )
    } else {
      this.$emit('on-handle-table-head-select', item, opt, type)
    }
    this.doClose()
  }

  setValue(val: string) {
    this.value = val
  }

  setSelectValue(val: string) {
    this.selectValue = val
  }

  closePopover(flag: boolean) {
    if (flag) {
      this.value = ''
    }
    this.doClose()
  }

  doClose() {
    (this.$refs.popoverRef as any).doClose()
  }
}
