import { render, staticRenderFns } from "./TsPagination.vue?vue&type=template&id=2f8a74a7&scoped=true&"
import script from "./TsPagination.vue?vue&type=script&lang=ts&"
export * from "./TsPagination.vue?vue&type=script&lang=ts&"
import style0 from "./TsPagination.vue?vue&type=style&index=0&id=2f8a74a7&prod&lang=css&"
import style1 from "./TsPagination.vue?vue&type=style&index=1&id=2f8a74a7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8a74a7",
  null
  
)

export default component.exports