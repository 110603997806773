import { StoryLineType } from '@/types/uploadStory'

/**
 * todo 故事线 所属类型 枚举值
    Invest("投资规划"),
    Family("家庭基础保障"),
    Child("教育规划"),
    Pension("退休规划"),
    OthersInvest(1, "其他-投资相关"),
    OthersRisk(1, "其他-潜在风险相关"),
    OthersAllocation(1, "其他-分配相关");
    PostInvestment(0, "投后分析");
 */
export const storyLineTypeListWealthFocus: StoryLineType[] = [
  // { type: 'Invest', value: '投资规划' },
  // { type: 'Family', value: '家庭基础保障' },
  // { type: 'Child', value: '教育规划' },
  // { type: 'Pension', value: '退休规划' },
  { type: 'OthersInvest', value: '财富聚焦-理念相关' },
  { type: 'OthersRisk', value: '财富聚焦-产品相关' },
  { type: 'OthersAllocation', value: '财富聚焦-行业相关' },
  // { type: 'PostInvestment', value: '投后分析' },
  // { type: 'ProductAnalysis', value: '产品分析' }
]

export const storyLineTypeList: StoryLineType[] = [
  { type: 'Invest', value: '投资规划' },
  { type: 'Family', value: '家庭基础保障' },
  { type: 'Child', value: '教育规划' },
  { type: 'Pension', value: '退休规划' },
  { type: 'OthersInvest', value: '财富聚焦-理念相关' },
  { type: 'OthersRisk', value: '财富聚焦-产品相关' },
  { type: 'OthersAllocation', value: '财富聚焦-行业相关' },
  { type: 'PostInvestment', value: '投后分析' },
  { type: 'ProductAnalysis', value: '产品分析' }
]

// 校验规则
export const rules = {
  storyLineName: [
    { required: true, message: '请输入故事线名称', trigger: 'blur' }
  ],
  storyLineType: [
    { required: true, message: '请至少选择一个所属分类', trigger: 'change' }
  ],
  marketName: [
    { required: true, message: '请输入故事线市场内名称', trigger: 'blur' }
  ],
  displayName: [
    { required: true, message: '请输入配置易展示名称', trigger: 'blur' }
  ],
  coverUrl: [{ required: true, message: '请上传封面图片', trigger: 'change' }],
  storyLineUrl: [
    { required: true, message: '请输入故事书链接', trigger: 'blur' }
  ]
}
