/**
 * IsToFixed 小数保留
 * @param num 需要处理的数字
 * @param digit 数位， 要*多少，默认1 ， 10， 100， 1000
 * @param fixed 保留几位小数 默认0 0,1,2
 */
export const IsToFixed = (
  num: number,
  digit: number = 1,
  fixed: number = 0
): number => +(num * digit).toFixed(fixed)

/**
 *isPercentageStr 百分比字符串
 * @param IsToFixed
 */
export const isPercentageStr = (IsToFixed: Function): string => IsToFixed + '%'

// 金额保留n位小数，整数补0，不足n补0
export const formatRoundToFixed = (num: number, digit = 1, fixed = 2) => {
  return (
    Math.round(num * digit * Math.pow(10, fixed)) / Math.pow(10, fixed)
  ).toFixed(fixed)
}
