




















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import TsUpload from '@/components/TsForm/tsUpload.vue'
import { StoryLineMap, StoryLineForm, GroupMap } from '@/types/uploadStory'
import {
  storyLineTypeListWealthFocus as _storyLineTypeList,
  rules as _rules
} from '../utils'
import { storyLineSave, storyLineIasList } from '@/api/uploadStory'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    TsUpload
  }
})
export default class Add extends Vue {
  @Prop() editInfo!: StoryLineMap | null
  activeName = 'base'
  formClone: StoryLineMap = {
    storyLineName: '',
    intro: '',
    coverUrl: '',
    comment: '',
    defaultSelected: false,
    storyLineType: '',
    marketName: '',
    marketDescription: '',
    displayName: '',
    iasGroupId: '',
    storyLineUrl: ''
  }

  form: StoryLineMap = cloneDeep(this.formClone)

  rules = _rules
  // groupList: GroupMap[] = []
  storyLineTypeList = _storyLineTypeList

  created() {
    if (this.editInfo) {
      this.form = this.editInfo
      // this.storyLineIasList(this.form.storyLineType)
    } else {
      this.form = cloneDeep(this.formClone)
    }
  }

  private handleUpload(imgUrl: string) {
    this.form.coverUrl = imgUrl
  }

  // private async storyLineIasList(storyLineType: string) {
  //   const { data } = await storyLineIasList({ storyLineType })
  //   this.groupList = data
  // }

  async confirm() {
    (this.$refs.form as unknown as StoryLineForm).validate(
      async(valid: boolean) => {
        if (valid) {
          await storyLineSave(this.form)
          this.$emit('on-close', true)
        }
      }
    )
  }
}
