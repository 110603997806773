<template>
  <div class="detail-tab-container">
    <div class="detail-tab-header">
      <div class="header-name-btn">
        <div class="header-title">
          <!-- <img src="@/packages/nb-amc-web-common/assets/images/detail_icon.png" /> -->
          <span class="iconfont amc-detail_icon detail_icon"></span>
          <span>{{ name || headerInfo.title }}</span>
          <el-tag
            v-if="headerInfo.status"
            class="header-title-tag"
            :type="headerInfo.status === '未激活' ? 'danger' : ''"
          >
            {{ headerInfo.status }}
          </el-tag>
        </div>
        <div>
          <div
            v-for="(item, index) in btnList"
            :key="index"
            class="btn-list-wrapper"
          >
            <el-button
              v-if="item.type === 'button'"
              class="header-el-button"
              @click="item.btnFuc"
            >
              {{ item.btnName }}
            </el-button>
            <el-tooltip :content="item.value ? '禁用' : '启用'" placement="top">
              <el-switch
                class="switchStyle"
                v-if="item.type === 'switch'"
                v-model="item.value"
                :active-value="false"
                :inactive-value="true"
                active-text="启用"
                inactive-text="禁用"
                @change="item.btnFuc"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="header-else">
        <div
          v-for="(item, index) in headerInfo.blockList"
          :key="index"
          class="header-else-item"
        >
          <div class="header-else-item-key">
            {{ item.key }}
          </div>
          <div class="header-else-item-value">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="detail-tab-content">
      <template v-if="Object.keys($slots).length > 0">
        <slot />
      </template>
      <template v-else>
        <el-tabs v-model="tabInfo.activeName" @tab-click="handleTabClick">
          <el-tab-pane
            v-for="(item, index) in tabInfo.options"
            :key="index"
            :label="item.label"
            :name="item.active"
          >
            <component
              :is="item.name"
              v-if="item.dataObj"
              v-bind="$attrs"
              :is-open="isOpen"
              :tab-name="tabName"
              :data-obj="item.dataObj"
              @on-edit-change="handleEditChange"
              @on-edit-data-change="handleEditDataChange"
            />
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
// import EditUserMsg from '@/components/UserDetailPage/EditUserMsg'
// import PermissionMsg from '@/components/UserDetailPage/PermissionMsg'
// import ActivationMsg from '@/components/activationManage/ActivationMsg'
@Component({
  name: 'TsDetailTab',
  components: {
    // EditUserMsg,
    // PermissionMsg,
    // ActivationMsg
  }
})
export default class extends Vue {
  @Prop({ default: Object }) headerInfo
  @Prop({ default: Object }) tabInfo
  @Prop({ default: Boolean }) isOpen
  @Prop({ default: Array }) btnList
  // EditUserMsg = EditUserMsg
  // PermissionMsg = PermissionMsg
  name = ''
  tabName = ''
  @Watch('isOpen')
  handleIsOpenChange(newV) {
    if (newV) {
      this.name = ''
    }
  }

  mounted() {
    this.tabName = 'one'
  }

  handleEditChange(value) {
    this.$emit('on-edit-change', value)
  }

  handleEditDataChange(name) {
    this.name = name
  }

  handleTabClick(tab, event) {
    this.tabName = tab.name
  }
}
</script>

<style lang="scss">
.switchStyle .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
.switchStyle .el-switch__label--left {
  z-index: 9;
  left: 20px;
}
.switchStyle .el-switch__label--right {
  z-index: 9;
  left: 0;
}
.switchStyle .el-switch__label.is-active {
  display: block;
}
.switchStyle.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
}
.detail-tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .detail-tab-header {
    height: 150px;
    padding: 0 30px 0 20px;
    .header-name-btn {
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .btn-list-wrapper {
        display: inline-block;
        margin-left: 10px;
      }
      .header-title {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        .detail_icon {
          color: $primaryColor;
          font-size: 30px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        .header-title-tag {
          margin-left: 15px;
        }
      }
    }
    .header-else {
      display: flex;
      height: calc(100% - 80px);
      padding: 0 0 0 40px;
      flex-direction: row;
      justify-content: left;
      .header-else-item {
        margin-right: 40px;
        .header-else-item-key {
          color: #999999;
          font-size: 14px;
        }
        .header-else-item-value {
          font-size: 18px;
          margin-top: 10px;
        }
      }
    }
  }
  .detail-tab-content {
    flex: 1;
    /*padding: 0 20px 20px 20px;*/
    background-color: #f3f3f4;
    .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
    .el-tabs {
      height: 100%;
      .el-tabs__header {
        background-color: #ffffff;
        padding: 0 20px;
      }
      .el-tabs__content {
        height: calc(100vh - 265px);
        overflow-y: auto;
        margin: 20px;
        background-color: #ffffff;
        border-radius: 2px;
      }
    }
  }
}
</style>
