












import { changeAvatarUrl } from '@/api/users'
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class TsUpload extends Vue {
  // @Prop({ default: {} }) uploadParam!: {} | null;
  @Prop({ default: {} }) imageSrc!: string
  imageUrl = this.imageSrc
  changeAvatarUrl = changeAvatarUrl
  tenantId = window.sessionStorage.getItem('tenantId')

  uploadHeader = {
    Authorization: 'bearer ' + window.sessionStorage.getItem('token'),
    tenantId: this.tenantId
  }

  private handleAvatarSuccess(res: any) {
    if (res.statusCode === 0 && res.data) {
      this.$message({
        type: 'success',
        message: '上传成功'
      })

      this.imageUrl = res.data
      this.$emit('on-upload', res.data)
    } else {
      this.$message({
        type: 'error',
        message: res.errorMsg
      })
    }
  }
}
