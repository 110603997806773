




















import { Component, Vue } from 'vue-property-decorator'
import { storyLineList, storyLineDetail } from '@/api/uploadStory'
import {
  StoryLineType,
  StoryLineMap
} from '@/types/uploadStory'
import { storyLineTypeList } from './utils'
import TsTable from '@/components/TsTable/TsTable.vue'
import Settings from './components/settings.vue'
@Component({
  name: '',
  components: {
    TsTable,
    Settings
  }
})
export default class extends Vue {
  drawerVisible = false
  editType = ''
  editInfo: StoryLineMap | null = null
  tableData = {
    data: [],
    currentPage: 0,
    pageSize: 20,
    total: 0
  }

  tableTitleObj = {
    title: '故事线列表',
    options: {
      dropdown: false,

      arr: [
        {
          type: 'add',
          name: '新增'
        }
      ]
    }
  }

  tableHead = [
    {
      type: 'first',
      propName: 'storyLineName',
      labelName: '故事线名称'
    },
    {
      propName: 'storyLineTypeStr',
      labelName: '所属规划'
    },
    {
      propName: 'comment',
      labelName: '备注'
    }
  ]

  created() {
    this.getStoryLineList()
  }

  async getStoryLineList() {
    const { data } = await storyLineList({
      page: this.tableData.currentPage,
      size: this.tableData.pageSize
    })
    data.content.forEach((item: StoryLineMap) => {
      const _storyLineTypeStr = storyLineTypeList.find(
        (w: StoryLineType) => w.type === item.storyLineType
      )
      item.storyLineTypeStr = (_storyLineTypeStr as StoryLineType).value
    })
    this.tableData.data = data.content
    this.tableData.total = data.totalElements
  }

  /**
   *   点击下拉或者按钮按钮触发
   *    type 按钮类型
   *    tableItem 如果按钮存在表格中 tableItem就是当前项
   */
  handleTableBtn() {
    this.editType = 'add'
    this.drawerVisible = true
  }

  // 点击表格第一列
  handleTableFirst({ id }: StoryLineMap) {
    this.getEditDetail(id || '')
  }

  async getEditDetail(id: string) {
    const { data } = await storyLineDetail({ storyLineId: id })
    this.editInfo = data
    this.editType = 'edit'
    this.drawerVisible = true
  }

  // 分页相关
  // 选择条数
  handleSizeChange(val: number) {
    this.tableData.pageSize = val
    this.tableData.currentPage = 0
    this.getStoryLineList()
  }

  // 当前第几页
  handleCurrentChange(val: number) {
    this.tableData.currentPage = val
    this.getStoryLineList()
  }

  handleDrawer(status: boolean) {
    if (status === true) {
      this.getStoryLineList()
    }
    this.editType = ''
    this.editInfo = null
    this.drawerVisible = false
  }
}
