













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TsPagination extends Vue {
  /**
   * paginationPosition  分页位置
   * pageSize 每页显示条目个数，支持 .sync 修饰符  10
   * currentPage 当前页数，支持 .sync 修饰符 1
   * total 总条目数
   *
   * handleSizeChange pageSize 改变时会触发
   * handleCurrentChange  currentPage 改变时会触发
   */

  @Prop({ default: 'right' }) private paginationPosition!: string
  @Prop({ default: 10 }) private pageSize!: number
  @Prop({ default: 0 }) private currentPage!: number | string
  @Prop() private total!: number

  pageSizes = [10, 20, 30, 40]

  handleSizeChange(val: number) {
    this.$emit('size-change', val)
  }

  handleCurrentChange(val: number) {
    this.$emit('current-change', val - 1)
  }
}
